A {text-decoration:none;}
.note{font-size:12px; line-height:20px; font-family:MS Gothic; color:#111111; text-decoration:none;}
.num1{font-size:24px; font-family:Verdana, Arial, Helvetica, sans-serif; color:#FFFFFF; font-weight:bold;}
.num2{font-size:12px; font-family:Verdana, Arial, Helvetica, sans-serif; color:#EEEEEE;}
.picdesc{font-size:12px; font-family:MS Gothic;}
.title{font-size:12px; font-family:MS Gothic; color:#FFFFFF;}
.titlee{font-size:14px; font-family:Verdana, Arial, Helvetica, sans-serif; color:#FFFFFF;}
.date{font-size:12px; font-family:MS Gothic; color:#FFFFFF;}
.nav{font-size:11px; font-family:Arial, Helvetica, sans-serif; color:#111111; text-decoration:none;}
.foot{font-size:10px; font-family:Verdana, Arial, Helvetica, sans-serif; color:#666666;}
.cal{font-size:14px; FONT-WEIGHT:bold; font-family:Verdana; color:#111111;}
.mail{font-size:11px; font-family:Vardana,Arial; color:#111111; text-decoration:none;}
.intro{font-size:12px; line-height:14px; font-family:MS Gothic; color:#666666;}
.mtitle{font-size:20px; font-weight:bold; font-family:Arial, Helvetica, sans-serif; color:#FFFFFF;}
.mnote{font-size:12px; line-height:18px; font-family:Verdana, Arial, Helvetica, sans-serif; color:#333333;}
.mquot{font-size:12px; line-height:18px; font-family:Verdana, Arial, Helvetica, sans-serif; color:#333333; font-style: italic}
.mrights{font-size:10px; line-height:12px; font-family:Verdana, Arial, Helvetica, sans-serif; color:#333333; font-style: italic}
.mfoot{font-size:10px; font-family:Verdana, Arial, Helvetica, sans-serif; color:#666666;}


